import classNames from 'classnames'

import { Link } from 'gatsby'

import React from 'react'

import browser from '@utils/browser'

import { LIST_NAVIGATION } from '../../constants'
import './Menu.scss'

type Params = {
  isActive: boolean
  setIsActiveMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const Menu = ({ isActive, setIsActiveMenu }: Params) => {
  return (
    <nav
      className={classNames('l-menu', {
        'l-menu--active': isActive,
      })}
    >
      <ul className="l-menu__list">
        {LIST_NAVIGATION.map((item, index) => (
          <li key={index} className="l-menu__list-item">
            <Link
              activeStyle={{ opacity: 1 }}
              to={item.path}
              onClick={() => {
                setIsActiveMenu(false)
                browser.scrollToTop()
                browser.scrollUnlock()
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu
