export const LIST_NAVIGATION = [
  {
    name: 'Inicío',
    path: '/',
  },
  {
    name: 'Destaques do dia',
    path: '/destaques-do-dia',
  },
  {
    name: 'Ações',
    path: '/acoes',
  },
  {
    name: 'FIIs',
    path: '/fiis',
  },
  {
    name: 'ETFs',
    path: '/etfs',
  },
  {
    name: 'BDRs',
    path: '/bdrs',
  },
]
