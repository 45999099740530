const roundingWithDecimalPlace = (number?: number) => {
  if (number) {
    const rouding = Math.round(number * 100) / 100

    return rouding
  }
}

const toCurrencyBRL = (number?: number | string) => {
  const toNumber = Number(number)
  const formatted = toNumber?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return formatted
}

const toRounding = (number?: number) => {
  if (number) {
    return Math.round(number)
  }
}

const roundingToTwoDecimals = (number?: number) => {
  if (number) {
    return parseFloat(number.toFixed(2))
  }
}

const percentageDiffBeforeAndAfter = (before?: number, after?: number) => {
  if (before === 0) {
    return '-'
  }

  if (after && before) {
    const percetage = ((after - before) / before) * 100
    return roundingToTwoDecimals(percetage)
  }

  return '-'
}

const isNegative = (number: number | string) => {
  const toInt = Number(number)
  return toInt < 0
}

function formatVolume(volume: number): string {
  if (volume >= 1e12) {
    return 'R$ ' + Math.floor(volume / 1e12) + 'T' // T para Trilhões
  } else if (volume >= 1e9) {
    return 'R$ ' + Math.floor(volume / 1e9) + 'B' // B para Bilhões
  } else if (volume >= 1e6) {
    return 'R$ ' + Math.floor(volume / 1e6) + 'M' // M para Milhões
  } else if (volume >= 1e3) {
    return 'R$ ' + Math.floor(volume / 1e3) + 'K' // K para Mil
  } else {
    return 'R$ ' + volume.toString() // Adiciona R$ para valores menores que mil
  }
}

const getPercentAvaragePrice = (current: number, old: number) => {
  const avarage = ((current - old) / old) * 100
  return avarage
}

export default {
  roundingToTwoDecimals,
  roundingWithDecimalPlace,
  percentageDiffBeforeAndAfter,
  formatVolume,
  toCurrencyBRL,
  getPercentAvaragePrice,
  toRounding,
  isNegative,
}
