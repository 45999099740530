import { useEffect, useRef, useState } from 'react'

import browser from '@utils/browser'

const useHeader = () => {
  const [isActiveMenu, setIsActiveMenu] = useState(false)

  const onToggleMenu = () => {
    if (isActiveMenu) {
      browser.scrollUnlock()
    } else {
      browser.scrollLock()
    }

    setIsActiveMenu(!isActiveMenu)
  }

  return {
    isActiveMenu,
    onToggleMenu,
    setIsActiveMenu,
  }
}

export default useHeader
