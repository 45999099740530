import { Link } from 'gatsby'

import React from 'react'

import Billboard from './components/Billboard'
import Menu from './components/Menu'

import If from '@components/If'

import IconClose from '@icons/close.svg'
import IconLogo from '@icons/logo.svg'
import IconMenu from '@icons/menu.svg'
import IconSearch from '@icons/search.svg'

import useScreenDetector from '@hooks/useScreenDetector'

import './Header.scss'
import { LIST_NAVIGATION } from './constants'
import useHeader from './useHeader'

const Header = () => {
  const { onToggleMenu, setIsActiveMenu, isActiveMenu } = useHeader()
  const { isMobile, isDesktop } = useScreenDetector()

  return (
    <>
      <header className="header">
        <div className="header__container">
          <div className="header__left">
            <Link
              to="/"
              onClick={() => {
                if (isActiveMenu) {
                  onToggleMenu()
                }
              }}
            >
              <IconLogo className="header__logo" />
            </Link>
            <If condition={isDesktop}>
              <ul className="header__nav">
                {LIST_NAVIGATION.map((item, index) => (
                  <li key={index} className="header__nav-item">
                    <Link activeStyle={{ opacity: 1 }} to={item.path}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </If>
          </div>

          <If
            condition={isActiveMenu}
            renderIf={
              <button onClick={onToggleMenu}>
                <IconClose />
              </button>
            }
            renderElse={
              <ul className="header__list">
                <If condition={isMobile}>
                  <li className="header__list-item">
                    <button title="abrir menu" onClick={onToggleMenu}>
                      <IconMenu />
                    </button>
                  </li>
                </If>

                <li className="header__list-item">
                  <button>
                    <IconSearch />
                  </button>
                </li>
              </ul>
            }
          />
        </div>
      </header>
      <Menu isActive={isActiveMenu} setIsActiveMenu={setIsActiveMenu} />
      <Billboard />
    </>
  )
}

export default Header
