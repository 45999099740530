const isClient = () =>
  !!(typeof window !== 'undefined' && window.document && window.document.createElement)

const scrollToTop = () => {
  if (isClient()) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const scrollTo = (id: string) => {
  if (isClient()) {
    const target = document.getElementById(id)
    target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

const scrollLock = () => {
  if (isClient()) {
    document.documentElement.style.overflow = 'hidden'
  }
}

const scrollUnlock = () => {
  if (isClient()) {
    document.documentElement.style.overflow = ''
  }
}

const loader = {
  show: () =>
    isClient() && document.querySelector('.loader')?.classList?.add('is-active'),
  hide: () =>
    isClient() &&
    setTimeout(
      () => document.querySelector('.loader')?.classList?.remove('is-active'),
      1200,
    ),
}

export default { isClient, scrollToTop, scrollTo, scrollLock, scrollUnlock, loader }
